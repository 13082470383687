import React, { useState, useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import ReactDOM from 'react-dom';
import styles2 from "./modalPopup.module.css";
import { useHistory } from 'react-router-dom';

export const BottomSheet = ({ 
  show, 
  onClose, 
  body, 
  height = 'auto', 
  footer, 
  title, 
  subtitle,
  isLongTitle,
  backButton, 
  text, 
  isResizeToFix,
  bottomSheetHeight
}) => {
  const bottomSheetRef = useRef(null);
  const resizeButtonRef = useRef(null);
  const [sheetHeight, setSheetHeight] = useState(height);
  const [tooLow, setTooLow] = useState(false);
  const history = useHistory();

  const handleDragStart = (e) => {
    
    const initialY = e?.touches?.[0]?.pageY;
    const initialHeight = bottomSheetRef?.current?.offsetHeight;

    const handleDragMove = (moveEvent) => {
      const deltaY = moveEvent?.touches?.[0]?.pageY - (initialY || 0);
      const newHeight = initialHeight - (deltaY || 0);
      if (deltaY > 100) {
        setTooLow(true);
      } else if (deltaY < 0) {
        setTooLow(false);
      }
      setSheetHeight(newHeight);
    };

    const handleDragEnd = () => {
      resizeButtonRef.current.removeEventListener('touchmove', handleDragMove, { passive: true });
      resizeButtonRef.current.removeEventListener('touchend', handleDragEnd);
    };

    resizeButtonRef.current.addEventListener('touchmove', handleDragMove, { passive: true });
    resizeButtonRef.current.addEventListener('touchend', handleDragEnd);
  };

  const onDragEnd = () => {
    if (!isResizeToFix) {
      if (tooLow) {
        onClose();
      } else {
        setSheetHeight(height)
      }
    }
  }

  useEffect(() => {
    // Add event listener for browser back button
    window.addEventListener('popstate', onClose);

    // Add event listener for mobile back button
    window.addEventListener('pagehide', onClose);

    if (show) {
      history.push(window.location.pathname);
    }

    // Cleanup event listeners when component unmounts
    return () => {
      window.removeEventListener('popstate', onClose);
      window.removeEventListener('pagehide', onClose);
    };
  }, [show]);

  useEffect(() => {
    if (show) {
      setSheetHeight(height);
    }
  }, [show]);

  return ReactDOM.createPortal(
    <div className={`${styles2.bottomSheetOverlay} ${show ? styles2.showSheet : ""}`} onClick={onClose}>
      <div
        className={styles2.bottomSheet}
        style={{ height: sheetHeight, maxHeight: bottomSheetHeight }}
        onClick={(e) => e.stopPropagation()}
        ref={bottomSheetRef}
      >
        <div className={styles2.resizeButton}
          ref={resizeButtonRef}
          onTouchStart={handleDragStart} 
          onTouchEnd={onDragEnd}
        />
        <div className={isLongTitle ? styles2.bottomSheetLongHeader : styles2.bottomSheetHeader}>
          {backButton && <button className={styles2.backButton}>Back</button>}
          <div></div>
          <button className={styles2.closeButton} onClick={onClose}><i className="fas fa-times"></i></button>
        </div>
        <div className={styles2.bottomSheetBody}>
        <span className={styles2.bottomSheetTitle}>{title}</span><br/>
        <div className="bottom-sheet-subtitle">
        <span >{subtitle}</span>
        </div>
        {body ? body :
            <span className="font-size-xl mt-3" >
                <p>{text}</p>
            </span>}
        </div>
        {footer && <div className={styles2.bottomSheetBottom}>
          {footer}
        </div>}
      </div>
    </div>,
    document.getElementById('bottom-sheet-root')
  );
};


function ModalPopUp( {handleCloseModal, text, headerText, show=true, isFooter=false, footerComponent, body, ariaLabelledby, mobileHeight,isBottomSheetRequired = true, isLongTitle }) {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768); // Initial check

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);
    return (
        (isMobile && isBottomSheetRequired) ? (
          <BottomSheet
            show={show}
            onClose={handleCloseModal}
            body={body}
            footer={footerComponent}
            height={mobileHeight}
            title={headerText}
            text={text}
            isLongTitle={isLongTitle}
          />
        ) : (
            <Modal
                show={show}
                onHide={handleCloseModal}
                aria-labelledby={ariaLabelledby || "example-modal-sizes-title-lg"}
                backdrop="static"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title
                        id={ariaLabelledby || "example-modal-sizes-title-lg"}
                        className="cm-modal-header-title"
                    >
                        <b>{headerText}</b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   {body ? body : <div className="my-5">
                        <span className="font-size-xl" >
                            <p>{text}</p>
                        </span>
                    </div>}
                </Modal.Body>
                {isFooter && (
                    <Modal.Footer>
                        {footerComponent}
                    </Modal.Footer>
                )}
            </Modal>
        )
    );
}

export default ModalPopUp;
