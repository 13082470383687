import React, { useEffect, useState } from 'react';
import styles from './CustomSelect.module.css'; // Add your custom styles here
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import SVG from 'react-inlinesvg';
import { useIntl } from 'react-intl';

const CustomSelect = ({ childBusinessList=[], onSelect, selectedBusiness }) => {
  const intl = useIntl();
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(true);
  const [filteredBusinessList, setFilteredBusinessList] = useState(childBusinessList);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (searchTerm === '') {
        setFilteredBusinessList(childBusinessList);
        return;
      }
      const filteredList = childBusinessList.filter((business) =>
        business &&
        (
          business?.state?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          business?.state?.abbreviation?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          business.name?.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
      setFilteredBusinessList(filteredList);
  
    }, 300);
  
    return () => clearTimeout(timeout);
  }, [searchTerm, childBusinessList]);

  const handleSelect = (business) => {
    onSelect(business);
    setIsDropdownOpen(false);
  };

  return (
    <div className={styles["custom-select-container"]}>
      <div className={styles["custom-select-input"]}>
        <span className="svg-icon p-2">
          <SVG src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")} />
        </span>
        <input
          type="text"
          placeholder={intl.formatMessage({
            id: "ParentBusiness.searchState.placeholder",
          })}
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </div>

      <div className={styles.gridContainer}>
        {filteredBusinessList?.length > 0 ? (
          filteredBusinessList.map((business) => (
            <div
              key={business?.businessId}
              className={`${styles.gridItem} ${selectedBusiness?.businessId === business?.businessId && styles.selected}`}
              onClick={() => handleSelect(business)}
            >
              <span className={styles["business-state"]}>{business?.state?.name}</span>
            </div>
          ))
        ) : (
          <div className={styles["no-results"]}>{intl.formatMessage({ id: "ParentBusiness.noResult" })}</div>
        )}
      </div>
    </div>
  );
};

export default CustomSelect;
