import React from "react";
import styles from "./listItem.module.css";

const ListItem = ({ leadingIcon, trailingIcon, label, subtexts, heading }) => {
  return (
    <div className={styles.container}>
      <div className={styles.container2}>
        {leadingIcon ? (
          <div className={styles.leadingIconContainer}>{leadingIcon}</div>
        ) : null}
        <div className={styles.listContent}>
          <div className={styles.heading}>
            <span>{heading}</span>
          </div>
          {subtexts?.length > 0 &&
            subtexts.map((subtext, index) => (
              <span className={styles.subheading} key={index}>
                {subtext}
              </span>
            ))}
        </div>
        <div className={styles.iconDiv}>
          {label ? <Label name={label} /> : null}
          {trailingIcon}
        </div>
      </div>
    </div>
  );
};

const Label = ({ name }) => {
  return <div className={styles.labelContainer}>{name}</div>;
};

export default ListItem;
