import React from 'react'
import styles from "./PrimaryButton.module.css"
import { ButtonBase } from '@material-ui/core';

/**
 * A component that renders a Material-UI ButtonBase with a primary theme.
 *
 * The component renders a Material-UI ButtonBase with a primary theme, and
 * disables touch ripple effect when the button is disabled.
 *
 * @param {ReactNode} children The text to display in the button.
 * @param {Boolean} disabled Whether the button is disabled.
 * @param {Object} rest Any other props to pass to ButtonBase.
 * @returns {ReactElement} - A React element representing the button.
 */
const PrimaryButton = ({children, disabled, fullWidth, roundedFull, ...rest}) => {
  return (
    <ButtonBase className={`${styles.primaryBtn} cm-bg-primary ${disabled && styles.disabledBtn}`} disabled={disabled} disableTouchRipple={disabled} {...rest} style={{width: fullWidth ? "100%" : "auto", borderRadius: roundedFull ? "100px" : "12px"}}>
      {children}
    </ButtonBase>
  )
}

export default PrimaryButton

export const Button = ({children, disabled, varient, fullWidth, ...rest}) => {
  return (
    <ButtonBase className={`${styles.btn} ${varient ? styles[varient] : styles.primary}`} style={{width: fullWidth ? "100%" : "auto"}} {...rest} >
      {children}
    </ButtonBase>
  )
}
