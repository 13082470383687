import React, { useEffect, useState } from "react";
import WidgetLayout from "./widgetLayout";
import { DataConstant, UrlConstant } from "../../constants";
import styles2 from "./widgetLayout.module.css";
import { useIntl } from "react-intl";
import { ConfirmationPopup } from "../../components/confirmationPopup";
import confirm from "../../assets/svg/ResponseRecorded.json";
import Lottie from "react-lottie";
import {
  handleAuthFetchGet,
  handleAuthFetchPost,
} from "../../../utils/apiCallsProvider";
import { convertUtcDateToTimeZoneDate, findMessage } from "../../../utils/helper";
import { NotificationManager } from "react-notifications";
import moment from "moment";
const { REACT_APP_IMAGEURL } = process.env;

const HcpAcceptDecline = ({
  match: {
    params: { code },
  },
}) => {
  const intl = useIntl();
  const [isConsultationAccepted, SetIsConsultationAccepted] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isResponseRecorded, SetIsResponseRecorded] = useState(false);
  const decodeString = (code)=>{
    try{
      if (typeof code === "string") {
        const parts = atob(code).split("/").map(Number);
        if (parts.length === 2) {
          const [hcpId, consultRequestId] = parts;
          return { hcpId, consultRequestId };
        }
      }
      // If any condition is not met, throw an error
      throw new Error("Invalid format");
    }
    catch(error){
      NotificationManager.error(intl.formatMessage({id: "InvalidCode"}));
      return {};
    }
  }
  let {hcpId, consultRequestId} = decodeString(code)// Here we are decoding the base64 and splitting at '/' to extract the hcpId and consultRequestId
  const [consultationDetails, setConsultationDetails] = useState(null);
  const getHCPBookingDetails = () => {
    handleAuthFetchGet(
      UrlConstant.bookingFlow.getHCPBookingDetails(hcpId, consultRequestId),
      false,
      true
    ).then((response) => {
      if (
        response &&
        response?.status?.toLowerCase() ===
          DataConstant.apiResponseStatus.success.toLowerCase() &&
        response?.data
      ) {
        setConsultationDetails(response?.data);
        if (response?.data?.isHcpResponded) {
          SetIsResponseRecorded(true);
        }
      } else {
        NotificationManager.error(findMessage(response?.message));
      }
    });
  };

  useEffect(() => {
    if(hcpId && consultRequestId){
      getHCPBookingDetails();
    }
  }, []);

  const ServiceDetails = () => {
    return (
      <>
        <div className="d-flex flex-column flex-grow-1">
          <span className="cm-text-dark mb-1 font-size-lg font-500">
            {intl.formatMessage({ id: "Consultation.ServiceDetails" })}
          </span>

          <span className="mb-1 font-400 font-size-14">
            {consultationDetails?.userBookingServiceDetails?.[0]?.name}
          </span>
        </div>
        <div className="separator separator-solid my-3 border-flash-white"></div>
      </>
    );
  };

  const ClientDetails = () => {
    return (
      <>
        <div className="d-flex align-items-center mt-2 my-4">
          <div className="mr-5 flex-shrink-0">
            <span className="symbol-label symbol symbol-55 symbol-light-success">
              <img
                className="my-0 h-75 align-self-end rounded-circle"
                src={REACT_APP_IMAGEURL + DataConstant.media.defaultUserPic}
                alt=""
              />
            </span>
          </div>
          <div className="d-flex flex-column flex-grow-1 font-weight-bold">
            <span className="text-dark  mb-1 font-size-lg font-600">
              {`${consultationDetails?.clientDetails?.[0]?.firstName} ${consultationDetails?.clientDetails?.[0]?.lastName}`}
            </span>
            <span className="cm-text-teritiary font-size-14 font-400">
              {consultationDetails?.clientDetails?.[0]?.email}
            </span>
            <span className="cm-text-teritiary font-size-14 font-400">
              {consultationDetails?.clientDetails?.[0]?.phone}
            </span>
          </div>
        </div>
        <div className="separator separator-solid my-3 border-flash-white"></div>
      </>
    );
  };

  const ServiceConsultationDetails = () => {
    return (
      <>
        <div className="d-flex flex-column flex-grow-1 my-4 ">
          <span className="mb-1 font-size-lg font-500 cm-text-dark">
            {intl.formatMessage({id: "ServiceConsultation.title"})}
          </span>
          <span className="mb-1 font-400 font-size-14">
            {intl.formatMessage(
              { id: "ServiceConsultation" },
              {
                client: <b>{consultationDetails?.requestedHcpName}</b>,
                 dayDate : (
                  <b>
                    {" "}
                    {moment(
                      consultationDetails?.patientSpecificBookingDetails
                        ?.psfServiceConsultRequests?.[0]?.scheduleDateTimeInUTC
                    ).format(DataConstant.dateFormats.dddd_Do_mmmmm_yy)}
                  </b>
                ),
                time:(
                  <b>
                    {intl.formatMessage({id: "at"})} {" "} 
                    {moment(convertUtcDateToTimeZoneDate(consultationDetails?.patientSpecificBookingDetails?.psfServiceConsultRequests?.[0]?.scheduleDateTimeInUTC)
                    ).format(DataConstant.dateFormats.hmmA)
                    }
                  </b>
                ),
                timeZone:
                  consultationDetails?.patientSpecificBookingDetails
                    ?.psfServiceConsultRequests?.[0]?.timeZoneShortName,
              }
            )}
          </span>
        </div>
        <div className="separator separator-solid my-3 border-flash-white"></div>
      </>
    );
  };

  const BusinessDetails = () => {
    return (
      <>
        <div className="d-flex flex-column flex-grow-1 my-4">
          <span className="cm-text-dark mb-1 font-size-lg font-500">
            {intl.formatMessage({ id: "BusinessDetails" })}
          </span>

          <span className=" mb-1 font-400 font-size-14">
            {consultationDetails?.businessDetails?.name}
          </span>
        </div>
        <div className="separator separator-solid my-3 border-flash-white"></div>
      </>
    );
  };

  const animationOptions = {
    loop: true,
    autoplay: true,
    animationData: confirm,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleAcceptConsult = () => {
    SetIsConsultationAccepted(true);
    setShowModal(true);
  };

  const handleDeclineConsult = () => {
    SetIsConsultationAccepted(false);
    setShowModal(true);
  };

  const onSubmit = () => {
    const requestParams = {
      userServiceConsultRequestId: consultRequestId,
      hcpUserDetailId: hcpId,
      answer: isConsultationAccepted ? "yes" : "no",
    };
    handleAuthFetchPost(
      UrlConstant.serviceConsult.hcpAcceptRejectConsultation,
      JSON.stringify(requestParams),
      "application/json;",
      false,
      true
    ).then((response) => {
      if (
        response &&
        response?.status?.toLowerCase() ===
          DataConstant.apiResponseStatus.success.toLowerCase() &&
        response?.data
      ) {
        SetIsResponseRecorded(true);
      } else {
        NotificationManager.error(findMessage(response?.message));
      }
    });
  };

  return (
    <WidgetLayout
      showHeaderNav={false}
      hideBusinessDetailsSection={true}
      hideGlobalAlert={true}
      actions={!isResponseRecorded}
      rightActionButton={
        consultationDetails ? (
          <div className={`d-flex justify-content-end my-4`}>
            <div>
              <button
                type="button"
                className={`btn btn-danger font-600`}
                style={{ backgroundColor: "#DC362E" }}
                onClick={handleDeclineConsult}
              >
                {intl.formatMessage({ id: "CannotAttend" })}
              </button>
            </div>
            <div className={`ml-2`}>
              <button
                type="button"
                className={`btn btn-primary font-600`}
                onClick={handleAcceptConsult}
              >
                {intl.formatMessage({ id: "WillAttend" })}
              </button>
            </div>
          </div>
        ) : null
      }
    >
      {!isResponseRecorded ? (
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              paddingTop: "24px",
            }}
          >
            <div style={{ flex: 1 }}>
              <div
                className={styles2.whiteLabelIcon}
                style={{ marginLeft: "0px", paddingBottom: "4px" }}
              >
                <img
                  src={
                    REACT_APP_IMAGEURL + DataConstant.media.defaultprofilepic
                  }
                  alt=""
                />
              </div>
              {consultationDetails ? (
                <>
                  {" "}
                  <div className="d-flex flex-column flex-grow-1 my-4">
                    <span className="font-700 font-size-20">
                      {intl.formatMessage({ id: "WillYouAttendConsult" })}
                    </span>
                  </div>
                  <div className="my-2">
                    <span className="font-700 font-size-16 cm-text-dark">
                      #{consultationDetails?.patientSpecificBookingDetails?.psfServiceConsultRequests?.[0]?.userServiceConsultRequestId}
                    </span>
                  </div>
                  <ClientDetails />
                  <ServiceConsultationDetails />
                  <BusinessDetails />
                  <ServiceDetails />
                </>
              ) : null}
            </div>

          </div>
          <ConfirmationPopup
            title={intl.formatMessage({ id: "ConfirmAction" })}
            message={
              isConsultationAccepted
                ? intl.formatMessage({
                    id: "YesAttendConsult",
                  })
                : intl.formatMessage({
                    id: "CannotAttendConsult",
                  })
            }
            onHide={() => setShowModal(false)}
            show={showModal}
            cancelButtonTitle={intl.formatMessage({ id: "Cancel" })}
            actionButtonTitle={intl.formatMessage({
              id: "medicationOrder.ConfirmOrder.label",
            })}
            actionMethod={onSubmit}
            isBottomSheetRequired={false}
          />
        </>
      ) : (
        <>
          <div className="d-flex flex-column justify-content-center align-items-center vh-100">
            <Lottie options={animationOptions} height={142} width={246} />

            <div className="text-center" style={{ width: "350px" }}>
              <span className="font-700 font-size-16">
                {intl.formatMessage({ id: "ResponseRecorded" })}
              </span>
            </div>
            <div className="text-center my-2" style={{ width: "350px" }}>
              <span className="font-400 font-size-14 cm-text-teritiary">
                {intl.formatMessage({
                  id: "ThanksForConfirmingAvailability",
                })}
              </span>
            </div>
          </div>
        </>
      )}
    </WidgetLayout>
  );
};

export default HcpAcceptDecline;
