import { DataConstant } from "../../../constants/dataConstant";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
export const appActionTypes = {
  SetParentBusinessDetails: "[Set ParentBusiness Details] Action",
  SetChildBusinessCodeList: "[Set ChildBusiness List] Action",
  UpdateAffiliateDetails: "[Update Affiliate Details] Action",
};

export const initialAppState = {
  parentBusinessDetails: {
    businessId: undefined,
    businessName: undefined,
    businessState: undefined,
    businessCountry: undefined,
    businessPhoneNumber: undefined,
    businessProfilePic: undefined,
    businessDescription: undefined,
    businessAdddress: undefined,
  },
  childBusinessCodeList: [],
  affiliateDetails: {
    url: "",
    code: "",
    affiliateId: 0,
    promoCode: "",
  },
};

export const appReducer = persistReducer(
  {
    storage,
    key: "app-detail",
  },
  (state = initialAppState, action) => {
    switch (action.type) {
      case appActionTypes.SetParentBusinessDetails: {
        const { 
          businessId,
          businessName,
          businessState,
          businessCountry,
          businessPhoneNumber,
          businessProfilePic,
          businessDescription,
          businessAdddress,
        } = action.payload;
        return { 
          ...state,  
          parentBusinessDetails: {
            businessId: businessId,
            businessName: businessName,
            businessState: businessState,
            businessCountry: businessCountry,
            businessPhoneNumber: businessPhoneNumber,
            businessProfilePic: businessProfilePic,
            businessDescription: businessDescription,
            businessAdddress: businessAdddress,
          }
        }
      };
      case appActionTypes.SetChildBusinessCodeList: {
        if (!Array.isArray(action.payload)) return { ...state, childBusinessCodeList: [] };
        return { ...state, childBusinessCodeList: action.payload };
      }
      case appActionTypes.UpdateAffiliateDetails: {
        return { ...state, affiliateDetails: {...state.affiliateDetails, ...action.payload} };
      }
      default:
        return state;
    }
  });
