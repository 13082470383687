import React from "react";
import styles from "./style.module.css";
export function ActionLoadingDialog({ isLoading, widgetCode }) {
  return (
    isLoading &&
    <div className={`cm-loader ${!widgetCode ? "mt-0" : ""}`}>
      <div className={styles.splash_screen}>
        <span className="progressbar-top" role="progressbar">
          <span className="progressbar-fill"></span>
          <span className="progressbar-fill progress-animation-2"></span>
        </span>
      </div>
    </div>
  );
}
