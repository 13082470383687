/* eslint-disable */
import { LoaderSlice } from "../redux/loader/loaderSlice";
import store from "../redux/store";
import { acquireAccessToken } from "../utils/authProvider";
import {
  signOut as authPopupSignOut,
  acquireAccessToken as authPopupAcquireAccessToken,
} from "../utils/authPopup";
import { DataConstant } from "../app/constants";
import { actionTypes } from "../app/modules/widget/_redux/widgetRedux";
import { actionTypes as consultationActionTypes } from "../app/modules/widget/_redux/consultationRedux";
import { actionTypes as authActionTypes } from "../app/modules/Auth/_redux/authRedux";
const { actions } = LoaderSlice;
const WhiteLabel = `${process.env.REACT_APP_WHITELABELNAME}`;

export const ClearUserDetail = () => {
  store.dispatch({
    type: actionTypes.ClearUserDetail,
  });
};
export const ClearBookingDetail = () => {
  store.dispatch({
    type: actionTypes.ClearBookingDetail,
  });
};

const ClearWidgetDetailsAfterLogout = () => {
  store.dispatch({
    type: actionTypes.ClearBookingDetailAfterLogout,
  });
}

export const ClearConsultationDetail = () => {
  store.dispatch({
    type: consultationActionTypes.setClearConsultationDetail,
  });
};
export const logout = () => {
  if (window.location !== window.parent.location) {
    authPopupSignOut();
  }
  else {
    store.dispatch({
      type: authActionTypes.SetUnAuthorizedByAPI,
      payload: true,
    });
  }
};
export const handleAuthFetchGet = async (
  requestURL,
  authorizationRequired = true,
  skipAuthWithWhiteLabel = false
) =>
  new Promise((resolve, reject) => {
    const options = {
      method: "get",
      headers: {
        "content-type": "application/json;",
      },
    };
    increaseCounter();
    if (authorizationRequired) {
      const getAccessToken =
        window.location !== window.parent.location
          ? authPopupAcquireAccessToken()
          : acquireAccessToken();
      getAccessToken.then((silentResponse) => {
        if (silentResponse) {
          const userDetail = JSON.parse(localStorage.getItem(DataConstant.userDetailTokenKey));
          options.headers.Authorization = `Bearer ${silentResponse.accessToken}`;
          options.headers.WhiteLabel = WhiteLabel;
          options.headers.UserDetail = userDetail;
          fetch(requestURL, options)
            .then(
              (response) => {
                return response.json();
              },
              (err) => {
                reject(err);
              }
            )
            .then(
              (responseJson) => {
                if (responseJson.statusCode === 401) {
                  console.log("responseJson-apiCallsProvider", responseJson);
                  ClearWidgetDetailsAfterLogout();
                  ClearConsultationDetail();
                  logout();
                  return;
                } else if (responseJson.statusCode === 404) {
                  window.location.href = DataConstant.Error404Page;
                  return;
                }
                resolve(responseJson);
              },
              (err) => {
                reject(err);
              }
            )
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              decreaseCounter();
            });
        }
      });
    } else {
      if(skipAuthWithWhiteLabel){
        options.headers.WhiteLabel = WhiteLabel;
      }
      fetch(requestURL, options)
        .then(
          (response) => {
            return response.json();
          },
          (err) => {
            reject(err);
          }
        )
        .then(
          (responseJson) => {
            resolve(responseJson);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          decreaseCounter();
        });
    }
  });

export const handleAuthFetchPost = async (
  requestURL,
  requestBody,
  requestHeader = "application/json;",
  authorizationRequired = true,
  skipAuthWithWhiteLabel = false
) =>
  new Promise((resolve, reject) => {
    const options = {
      method: "post",
      headers: {
        "content-type": "application/json;",
      },
      body: requestBody,
    };

    if (requestHeader) {
      options.headers["content-type"] = `${requestHeader}`;
    }
    increaseCounter();
    if (authorizationRequired) {
      const getAccessToken =
        window.location !== window.parent.location
          ? authPopupAcquireAccessToken()
          : acquireAccessToken();
      getAccessToken.then((silentResponse) => {
        if (silentResponse) {
          let responsedata;

          const userDetail = JSON.parse(localStorage.getItem(DataConstant.userDetailTokenKey));
          options.headers.Authorization = `Bearer ${silentResponse.accessToken}`;
          options.headers.WhiteLabel = WhiteLabel;
          options.headers.UserDetail = userDetail;
          fetch(requestURL, options)
            .then(
              (response) => {
                responsedata = response;
                return response.json();
              },
              (err) => {
                reject(err);
              }
            )
            .then(
              (responseJson) => {
                if (responseJson.statusCode === 401) {
                  console.log("responseJson-apiCallsProvider", responseJson);
                  ClearWidgetDetailsAfterLogout();
                  ClearConsultationDetail();
                  logout();
                  return;
                } else if (responseJson.statusCode === 404) {
                  window.location.href = DataConstant.Error404Page;
                  return;
                }
                if (responsedata.status !== 200) {
                  reject(responseJson);
                } else {
                  resolve(responseJson);
                }
              },
              (err) => {
                reject(err);
              }
            )
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              decreaseCounter();
            });
        }
      });
    } else {
      if(skipAuthWithWhiteLabel){
        options.headers.WhiteLabel = WhiteLabel;
      }
      fetch(requestURL, options)
        .then(
          (response) => {
            return response.json();
          },
          (err) => {
            reject(err);
          }
        )
        .then(
          (responseJson) => {
            if (responseJson.statusCode === 401) {
              console.log("responseJson-apiCallsProvider", responseJson);
              ClearWidgetDetailsAfterLogout();
              ClearConsultationDetail();
              logout();
              return;
            } else if (responseJson.statusCode === 404) {
              window.location.href = DataConstant.Error404Page;
              return;
            }
            resolve(responseJson);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          decreaseCounter();
        });
    }
  });

export const handleAuthFetchFileUploadPost = async (
  requestURL,
  requestBody,
  authorizationRequired = true
) =>
  new Promise((resolve, reject) => {
    const options = {
      method: "post",
      headers: {
        Accept: "application/json",
      },
      body: requestBody,
    };
    increaseCounter();
    if (authorizationRequired) {
      const getAccessToken =
        window.location !== window.parent.location
          ? authPopupAcquireAccessToken()
          : acquireAccessToken();
      getAccessToken.then((silentResponse) => {
        if (silentResponse) {
          let responsedata;
          const userDetail = JSON.parse(localStorage.getItem(DataConstant.userDetailTokenKey));

          options.headers.Authorization = `Bearer ${silentResponse.accessToken}`;
          options.headers.WhiteLabel = WhiteLabel;
          options.headers.UserDetail = userDetail;
          fetch(requestURL, options)
            .then(
              (response) => {
                responsedata = response;
                return response.json();
              },
              (err) => {
                reject(err);
              }
            )
            .then(
              (responseJson) => {
                if (responseJson.statusCode === 401) {
                  ClearWidgetDetailsAfterLogout();
                  ClearConsultationDetail();
                  logout();
                  return;
                }
                if (responsedata.status !== 200) {
                  reject(responseJson);
                } else {
                  resolve(responseJson);
                }
              },
              (err) => {
                reject(err);
              }
            )
            .catch((err) => {
              reject(err);
            })
            .finally(() => {
              decreaseCounter();
            });
        }
      });
    } else {
      fetch(requestURL, options)
        .then(
          (response) => {
            return response.json();
          },
          (err) => {
            reject(err);
          }
        )
        .then(
          (responseJson) => {
            resolve(responseJson);
          },
          (err) => {
            reject(err);
          }
        )
        .catch((err) => {
          reject(err);
        })
        .finally(() => {
          decreaseCounter();
        });
    }
  });

export const increaseCounter = (apiName) => {
  store.dispatch(actions.setLoader());
};

export const decreaseCounter = () => {
  store.dispatch(actions.decreaseApiCount());
};
